import React from 'react';
import { Card, ResourceList, TextStyle } from '@shopify/polaris';
import { useQuery } from 'urql';

const Queries = {
  Products: `
  {
    store(id: "kennship-test-store") {
      id
      products(first: 5) {
        edges {
          node {
            id
            handle
            title
            description
          }
        }
      }
    }
  }  
  `,
};

const MOCK_DATA = Array.from(Array(5), (_, i) => ({
  id: `mock-${i}`,
  handle: '...',
  title: 'Loading...',
  description: 'Loading...',
}));

function getNode({ node }) {
  return node;
}

export default function ProductList() {
  const [{ fetching, data }] = useQuery({ query: Queries.Products });
  const items = React.useMemo(() => {
    if (!data) return null;
    return data.store.products.edges.map(getNode);
  }, [data]);

  return (
    <Card>
      <ResourceList
        resourceName={{ singular: 'product', plural: 'products' }}
        loading={fetching}
        items={fetching || !items ? MOCK_DATA : items}
        renderItem={({ id, handle, title, description }) => (
          <ResourceList.Item id={id}>
            <h3>
              <TextStyle variation="Strong">{title}</TextStyle>
            </h3>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </ResourceList.Item>
        )}
      />
    </Card>
  );
}
