import React from 'react';
import StyleProvider from './StyleProvider';
import ShopifyProvider from './ShopifyProvider';
import GraphqlProvider from './GraphqlProvider';

export default function RootProvider({ children }) {
  return (
    <GraphqlProvider>
      <StyleProvider>
        <ShopifyProvider>{children}</ShopifyProvider>
      </StyleProvider>
    </GraphqlProvider>
  );
}
