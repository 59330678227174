import React from 'react';
import { Provider, createClient } from 'urql';

const graphqlPath = '/graphql';

function getApiEndpoint() {
  const { hostname } = document.location;
  const targetHostname =
    'shopkeeper-api' + hostname.slice(hostname.indexOf('.'));
  return `https://${targetHostname}${graphqlPath}`;
}

const client = createClient({
  url: getApiEndpoint(),
  fetchOptions: { mode: 'cors', credentials: 'include' },
});

export default function GraphqlProvider({ children }) {
  return <Provider value={client}>{children}</Provider>;
}
