import React from 'react';
import { Layout, Card } from '@shopify/polaris';
import ProductList from './ProductList';

import Box from '@material-ui/core/Box';

function App() {
  return (
    <Layout>
      <Box m={2}>
        <Layout.Section>
          <Card title="Welcome to Shopkeeper" sectioned>
            New features will be available soon -- we're working on it.
          </Card>
          <ProductList />
        </Layout.Section>
      </Box>
    </Layout>
  );
}

export default App;
