import React from 'react';
import ThemeProvider from '@kennship/theme';
import Helmet from 'react-helmet';

const POLARIS_CSS_URL =
  'https://sdks.shopifycdn.com/polaris/3.20.0/polaris.min.css';

export default function StyleProvider({ children }) {
  return (
    <ThemeProvider>
        <>
          <Helmet>
            <link rel="stylesheet" href={POLARIS_CSS_URL} />
          </Helmet>
          {children}
        </>
    </ThemeProvider>
  );
}
